
<div class="email-container-card">
    <MeetingPopup 
     v-if="openMeetingPopup"
     :openDrawer="openMeetingPopup" 
     :flag="popupFlag"
     :meetingData="items"
        :admin="admin"
     :header="headerData"
     :leadData="leadData"
     :tabType="tab"
     @onClickClose="handleMeetingPopupVisibility"
      @updateMeeting="updateMeeting"
      @removeMeeting="removeNonAttendeeMeeting"
      />

      <GlobalDelete
        v-if="openDeleteDialogPopup"
         @confirm="confirmMeetingDelete"
          @onClickClose="openDeleteDialog"
          :message="'Do you want to cancel this meeting?'"
          :openDrawer="openDeleteDialogPopup"
          />


      <div v-if="loading">
        <el-skeleton
         :rows="3" 
         animated
          />
      </div>
 <div 
 v-else
 class="head-container"
  >
        <div
         class="icon-container" 
         @click="handleEmailVisibility"
         >
            <i 
            v-if="!isVisible" 
            class="el-icon-arrow-right"
             />
            <i 
            v-else
             class="el-icon-arrow-down"
              />
        </div>
        <div class="heading-container">
            <p class="email-description-content">
                Meeting - {{ items.subject }}
            </p>
        </div>
         <div class="action-container">
            <p 
            v-if="items.htmlLink"
            @click="copyToClipboard(items.htmlLink)"
             >
                View
            </p>
            <p 
             v-if="items.hangoutLink"
             @click="copyToClipboard(items.hangoutLink)"
             >
                Join
            </p>
            <p  
            v-if="items.organized_by == admin"
            @click="handleMeetingPopupVisibility"
            >
                Edit
            </p>
            <p @click="openDeleteDialog">
                Cancel
            </p>
        </div>
    </div>
    <div 
    v-if="!loading"
    class="email-info-container"
     >
        <div>
            <div
            style="padding-left: 20px;"
            >
                <p class="person-info">Organised by {{items.organized_by}}</p>
                <p class="person-info">{{items.time}}</p>
            </div>
            <div
                 v-if="isVisible"
                class="email-content-details"
            >
                <div
                v-if="items.description"
                 class="meeting-content"
                   >
                   <p class="meeting-description">
                    Meeting Description
                   </p>
                   <div
                    v-html="htmlContent" 
                   />
                </div>

                <div class="meetings-info">
                    <div>
                        <p class="info-header">Attendees</p>
                        <p class="info-description"> {{items.attending_members.length}} Attendees</p>
                    </div>
                    <div>
                        <p class="info-header">Duration</p>
                        <p class="info-description">{{items.duration}}</p>
                    </div>
                </div>
            </div>
            <div
             v-else
             class="email-content-details"
             >
                    <p
                    v-if="isGoogleAccount"
                     class="restrictedClass">
                      {{items.description}}
                    </p>
                    <div
                    v-if="isMicrosoftAccount"
                    v-html="items.description" 
                   />

            </div>
        </div>
    </div>
</div>
