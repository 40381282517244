
<div class="email-container-card">
    <EmailPopup
         v-if="openEmailPopup"
        :openDrawer="openEmailPopup"
        :emailData="mailDetails"
        :leadData="lead"
        :admin="admin"
        @onClickClose="handleOpenEmailPopup"
        @addEmailToListEmailView="addEmailToListEmail"
       :flag="emailOperationFlag"
       :popupHeader="popupHeader"
        :tabType="tab"
       />

       <GlobalDelete
        v-if="openDeleteDialogPopup"
         @confirm="handleDeleteEmail"
          @onClickClose="openDeleteDialog"
          :message="'Do you want to delete this email'"
          :openDrawer="openDeleteDialogPopup"
          />

       <div v-if="loading">
            <el-skeleton
             :rows="3" 
             animated
              />
          </div>
<div v-else>
    <div 
    class="head-container">
        <div
         class="icon-container" 
         @click="handleEmailVisibility"
         >
            <i 
            v-if="!isVisible" 
            class="el-icon-arrow-right"
             />
            <i 
            v-else
             class="el-icon-arrow-down"
              />
        </div>
        <div class="heading-container">
            <p 
            v-if="isVisible"
             class="email-description-content"
             :style="emailStatusStyling()"
             >
                Email - {{visibleData.headers? visibleData.headers.subject?visibleData.headers.subject:visibleData.headers.Subject:visibleData?.subject||""  }}
            </p>
            <p 
            v-else
            :style="emailStatusStyling()"
            class="email-description-content"
            >
                Email - {{ mailDetails.subject?mailDetails.subject:mailDetails.Subject }}
            </p>
        </div>
         <div class="action-container">
            <p @click="handleModalType('reply')">
                Reply All
            </p>
            <p @click="handleModalType('forward')">
                Forward
            </p>
            <p @click="openDeleteDialog">
                Delete
            </p>
            <!-- <el-popconfirm
                confirm-button-text='Yes'
                cancel-button-text='No'
                icon="el-icon-info"
                icon-color="red"
                title="Are you sure to delete this mail?"
                @confirm="handleDeleteEmail"
            >
                <p slot="reference">Delete</p>
            </el-popconfirm> -->
        </div>
    </div>
    <div class="email-info-container">
        <div 
        v-if="isVisible"
        class="profile-picture-container" 
        >
            <el-avatar icon="el-icon-user-solid" />
        </div>

        <div class="email-details-container">
            <div
            class="email-person-details"
            :style="{paddingLeft: isVisible ? '0px' : '20px'}"
            >
              <div class="person-name">
                <p v-if="isVisible" :style="emailStatusStyling()">
                    {{visibleData.headers? visibleData.headers.From:visibleData?.from||""}}
                </p>
                <p v-else :style="emailStatusStyling()">
                    {{ mailDetails.from}}
                </p>
              </div>
              <div>
                <p
                v-if="isVisible"
                 class="person-info"
                  >
                  to {{ visibleData.headers["to"] ? visibleData.headers["to"] : visibleData.headers["To"] }}
                  {{ ccContent}}
                </p>
                <p 
                v-else
                class="person-info"
                 >
                       to {{ mailDetails.to !== 'Unknown' ? mailDetails.to :lead.lead_details.email ?lead.lead_details.email:lead.email }}
                </p>
              </div>
                <div class="person-info">
                    <p v-if="isVisible">
                        {{formatDateString(visibleData?.headers?.Date||visibleData?.sentDateTime||"")}}
                    </p>
                    <p v-else>
                        {{mailDetails.date ? formatDateString(mailDetails.date) : "" }}
                    </p>
                </div>
            </div>
            <div
                 v-if="isVisible"
                v-html="htmlContent"
            />
            <div
             v-else
              class="email-content-details"
              >
               <p style="margin-left: 20px;"> 
                Hey, this is a response...
            </p>
            </div>
            <img 
            v-if="isVisible"
            style="margin-top: 10px"
            src="./assets/Arka.png" 
            alt=""
             >
        </div>
    </div>
</div>

</div>
