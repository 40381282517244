<template>
  <div
    class="tabsContainer"
    :class="activeName == 'first' ? 'first' : ''"
    v-loading="!$props.lead"
  >
  <div style="z-index: 9999;">
      <EmailPopup
       v-if="openEmailPopup"
        :openDrawer="openEmailPopup"
        :leadData="lead"
        :admin="admin"
         @onClickClose="handleOpenEmailPopup" 
         @addEmailToListEmailView="addEmailToListEmailView"
         />
      <MeetingPopup  
        v-if="openMeetingPopup"
          :admin="admin"
        :leadData="lead"
       :openDrawer="openMeetingPopup"
        @onClickClose="handleOpenMeetingPopup"
        @removeMeeting="removeMeetingFromTheListMeetingView" 
        @addMeeting="addMeetingToListMeetingView"
        />
    </div>
    <el-tabs  @tab-click="onTabClick" v-model="activeName">
      <el-tab-pane
        label="Activity Timeline"
        name="first"
        class="commonTabClass"
      >
        <ActivityTimeline
          :isDawer="true"
          :focusAddNote="focusAddNote"
          :lead="lead"
        />
      </el-tab-pane>
      <el-tab-pane label="Tasks" name="second" class="commonTabClass">
        <div class="task-container">
          <Tasks
            :lead="lead"
            :project_id="project_id"
            :sizeType="'medium'"
            :isLeadSummary="lead.lead_details.id"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Documents" name="third" class="commonTabClass">
        <Documents  ref="documentsComponent" :lead="lead" />
      </el-tab-pane>
      <!-- email---------------------------------------------------------------------------------------- -->
      <el-tab-pane label="Emails" name="email" class="commonTabClass" >
        <div
         v-if="!isIntegrated"
          style="margin-top: 10px;"
         >
          <IntegrationPage />
        </div>
        <div 
        v-else
         style="padding: 10px;"
        >
        <div 
        class="create-email"
         >
          <el-button
          type="primary" 
          :disabled="fetchEmailLoading || !givenPermissionForEmail"
          @click="handleOpenEmailPopup"
         >
                    Create New Email
          </el-button>
          </div>
        <div v-if="emailInfo.length > 0">
        <div 
        v-for="(item, index) in filteredEmailInfo"
        :key="index"
        style="padding: 10px;"
        >
        <EmailDropdown 
          :item="item.messages[0]" 
          :lead="lead"
          :admin="admin"
          @removeEmail="removeEmailFromTheListEmailView"
           @addEmailToListEmailView="addEmailToListEmailView"
           @updateData="updateData"
         />
      </div>
      <div v-if="fetchEmailLoading">
        <el-skeleton
          style="height: 100px;"
         :rows="3" 
         animated 
         />
      </div>
      </div>
      <div v-else>
        <div
       v-if="fetchEmailLoading"
       style="padding: 10px;"
       >
        <el-skeleton
          style="height: 100px;"
         :rows="3" 
         animated 
         />
      </div>
      <div v-else>
         <NoDataPage :permission="givenPermissionForEmail"/>
        </div>
      </div>
      </div>
      </el-tab-pane>
      <!-- meetings --------------------------------------------------------------------------------------->
       <el-tab-pane label="Meetings" name="calendar" class="commonTabClass" >
        <div
         v-if="!isIntegrated" 
         style="margin-top: 10px;"
         >
          <IntegrationPage />
        </div>
        <div 
        v-else
        style="padding: 10px;"
        >
          <div
          class="create-email"
          >
          <el-button
          type="primary" 
          :disabled="fetchMeetingLoading ||!givenPermissionForMeeting"
         @click="handleOpenMeetingPopup"
         >
                    Create New Meeting
          </el-button>
          </div>
          <div v-if="eventsInfo.length > 0">
            <div
          v-for="(item, index) in filteredEventsInfo" 
           
          :key="index"
          style="padding: 10px;"
        >
          <MeetingsDropdown
          :items="item"  
           :admin="admin"
           :leadData="lead"
          @removeMeeting="removeMeetingFromTheListMeetingView" 
          @updateNewMeeting="updateNewMeeting"
          />
        </div>  
        <div v-if="fetchMeetingLoading">
                <el-skeleton
                  style="height: 100px;"
                  :rows="3" 
                  animated 
                />
                </div>
          </div>
        <div v-else>
          <div
       v-if="fetchMeetingLoading"
       style="padding: 10px;"
       >
        <el-skeleton
          style="height: 100px;"
         :rows="3" 
         animated 
         />
      </div>
          <NoDataPage 
          v-else
          :message="'meeting is scheduled.'"
           :permission="givenPermissionForMeeting"
           />
        </div>
  </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Tasks from "./tasks.vue";
import Documents from "./documents.vue";
import ActivityTimeline from "./activityTimeline.vue";
import EmailDropdown from "../../GlobalComponenets/emailDropdown.vue";
import MeetingsDropdown from "../../GlobalComponenets/meetingsDropdown.vue";
import EmailPopup from "../../GlobalComponenets/emailPopup.vue";
import {calculateDuration, formatDate, convertToUTC} from "../../GlobalComponenets/utils/utils"
import MeetingPopup from "../../GlobalComponenets/meetingPopup.vue";
import IntegrationPage from "../../GlobalComponenets/IntegrationPage.vue";
import NoDataPage from "../../GlobalComponenets/noDataPage.vue";
import API from "@/services/api/";
import { useEmailStore } from "../../../stores/email";
import { mapActions, mapState } from "pinia";
export default {
  components: {
    Tasks,
    Documents,
    ActivityTimeline,
    EmailDropdown,
    MeetingsDropdown,
    EmailPopup,
    MeetingPopup,
    IntegrationPage,
    NoDataPage,
  },
  props: {
    tasks: {
      type: Array,
      default() {
          return []
      }
    },
    project_id: {
      type: Number,
    },
    lead: {
      type: Object,
      default() {
          return {}
      }
    },
    focusAddNote: {
      type: Boolean,
      default: false,
    },
    activeTab: String,
  },
  data() {
    return {
      activeName: "first",
      emailInfo:[
      ],
      eventsInfo:[],
      openEmailPopup: false,
      openMeetingPopup: false,
      fetchEmailLoading:false,
      fetchMeetingLoading:false,
      isIntegrated:false,
      admin:"",
      givenPermissionForEmail:true,
      givenPermissionForMeeting:true,
    };
  },

  created() {
    if (this.activeTab) {
      this.activeName = this.activeTab;
    }
    this.getAccountConnectionStatus()
  },

  methods: {
    ...mapActions(useEmailStore, {
      setActiveAccount: "SET_ACTIVE_ACCOUNT",
      getAllMails: "GET_ALL_MAILS",
    }),
    async getEmailList (){
      this.fetchEmailLoading = true
      const body = {
        email:this.lead.lead_details.email
      }
      try{
        const {data} = this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.LIST_EMAIL(body):await API.EXTERNAL_INTEGRATION.LIST_MICROSOFT_EMAIL(body)
        this.emailInfo = data?.threads||[];
      }catch(er){
         let message = "Unable to get email list, please disconnect and Integrate again with all the email related permissions.";
         if(er.response.data.error){
          const errorMessage =er.response.data.error.toString()
         const detailsRegex = /Details: "(.*)"/;
        const detailsMatch = errorMessage.match(detailsRegex);
        if(detailsMatch){
          const parsedDetails = JSON.parse(detailsMatch[1].replace(/'/g, '"'));
          if(parsedDetails[0].reason == "insufficientPermissions")
          this.givenPermissionForEmail = false
          message = "You don't have enough permissions to access this email list, please disconnect and Integrate again with all the email related permissions."
        }
         }

        this.$message({
                showClose: true,
                message: message,
                type: "error",
                center: true
                })
        
      }
      this.fetchEmailLoading = false
    },

    async getGoogleUserDetails(){
      try{
        const {data} = await API.EXTERNAL_INTEGRATION.GET_GOOGLE_USER_INFO()
        this.admin = data.profile.emailAddress
      }
      catch(err){
       console.log(err)
      }

    },

    async getMicroSoftUserDetails(){
      try{
        const {data} = await API.EXTERNAL_INTEGRATION.GET_MICROSOFT_USER_INFO()
        this.admin = data.profile.mail
      }
      catch(err){
       console.log(err)
      }

    },

  async getEvents(){
    this.fetchMeetingLoading = true
    const attendees = {
      attendees:[this.lead.lead_details.email]
    }
    try{
      const {data} = this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.LIST_EVENTS(attendees):await API.EXTERNAL_INTEGRATION.LIST_MICROSOFT_EVENTS(attendees)
      if(!data?.data){
        this.fetchMeetingLoading = false
                return
      }
      const filteredEvents = data?.data.filter(item => item.status != "cancelled" )
      filteredEvents.forEach((item) =>{
        item.subject = this.isMicrosoftAccount?item.subject :item.summary,
        item.organized_by = item.organizer ?  item.organizer.email : "Unknown",
        item.attending_members = item.attendees ? item.attendees : [],
        item.start.dateTime ? item.duration = calculateDuration(item.start.dateTime,item.end.dateTime ) : item.duration = "N/A"
        item.start.dateTime ?  item.time = formatDate(item.start.dateTime) : item.time = item.start.date
        item.start.dateTime ? item.date = item.start.dateTime.split("T")[0] : item.date = item.start.date
        item.start.dateTime ? item.start_time = convertToUTC(item.start.dateTime): item.start_time = "00:00"
        item.start.dateTime ? item.end_time = convertToUTC(item.end.dateTime): item.end_time = "00:00"
        if(this.isMicrosoftAccount){
          item['htmlLink']=item.webLink;
          item['hangoutLink']=item?.onlineMeeting?.joinUrl;
          const htmlBody=item.bodyPreview.replace(/(\r\n[\s\S]*)/, "");
          item['description']=htmlBody;
          item['organized_by']=item.organizer?.emailAddress?.address||"Unknown";
        }
      })
      this.eventsInfo = filteredEvents
    }
    catch(err){
             let message = "Unable to get events list,please disconnect and Integrate again with all the meeting related permissions. ";
             if(err.response.data.error){
              const errorMessage =err.response.data.error.toString()
         const detailsRegex = /Details: "(.*)"/;
        const detailsMatch = errorMessage.match(detailsRegex);
        if(detailsMatch){
          const parsedDetails = JSON.parse(detailsMatch[1].replace(/'/g, '"'));
          if(parsedDetails[0].reason == "insufficientPermissions")
          this.givenPermissionForMeeting = false
          message = "You don't have enough permissions to access this meeting list, please disconnect and Integrate again with all the meeting related permissions."
        }
             }

      this.$message({
                showClose: true,
                message: message,
                type: "error",
                center: true
                })
    }
    this.fetchMeetingLoading = false
  },

  async getAccountConnectionStatus(){
    try{
            const {data} = await API.EXTERNAL_INTEGRATION.GET_GOOGLE_INTEGRATION_INFO();
            const outlookData= await API.EXTERNAL_INTEGRATION.GET_MICROSOFT_INTEGRATION_INFO();
            this.setActiveAccount(data.Account_connected?"gmail":outlookData.data.Account_connected?"outlook" :"")      
            this.isIntegrated =this.isGoogleAccount||this.isMicrosoftAccount ;
            if(this.isGoogleAccount){
             await this.getGoogleUserDetails()
              }
               if(this.isMicrosoftAccount){
                await this.getMicroSoftUserDetails()
              }
            
    }catch(err){
      console.error(err)
    }
             
              
  },

    onTabClick(tab, event) {
      if (tab.name === 'third') { // Check if the clicked tab is "Documents"
        this.$refs.documentsComponent.getFiles(); // Call function in Documents component
      }
    },
    handleOpenEmailPopup(){
     this.openEmailPopup = !this.openEmailPopup
    },

    handleOpenMeetingPopup(){
     this.openMeetingPopup = !this.openMeetingPopup
    },

    addEmailToListEmailView(data){
      this.fetchEmailLoading  = true;
      this.emailInfo= this.emailInfo.filter(obj => obj.thread_id !== data.thread_id);
        this.emailInfo.unshift(data);
      setTimeout(() => this.fetchEmailLoading=false, 100)
    },

    updateData(data){
      const newData = this.emailInfo.filter(item => item.id !== data.id)
      const addData = {
        id: data.id,
        from: data.headers.From,
        date: data.headers.Date,
        to: data.headers.to,
        body:data.body
      }
      newData.unshift(addData)
      this.emailInfo = newData;
    },


    addMeetingToListMeetingView(data){
      this.fetchMeetingLoading = true
      this.eventsInfo.unshift(data);
      setTimeout(() => this.fetchMeetingLoading=false, 100)
    },

    updateNewMeeting(){
      this.getEvents()
    },


    removeEmailFromTheListEmailView(id){
      const updatedEmails = this.emailInfo.filter(item => item.messages[0].id !== id)
      this.emailInfo = updatedEmails;
    },



    removeMeetingFromTheListMeetingView(id){
      const updatedEvents = this.eventsInfo.filter(item => item.id!== id)
      this.eventsInfo = updatedEvents;
    },
  },
  watch:{
    isGoogleOrMicrosoft:{
        handler(val){
          if(val==="outlook"){
            this.getEmailList();
            this.getAllMails(null)
          }

        }
    },
    activeName:{
      handler(val){
        if(val==="email"&&this.admin){
          this.getEmailList()
        }
        if(val==="calendar"&&this.admin){
          this.getEvents()
        }

      }
    }
  },
  computed: {
    ...mapState(useEmailStore,{
    isMicrosoftAccount:(state)=>state.isMicrosoftAccount,
    isGoogleAccount:(state)=>state.isGoogleAccount,
    isGoogleOrMicrosoft:state=>state.isGoogleOrMicrosoft,
  }),
    filteredEmailInfo() {
      return !this.fetchEmailLoading ? this.emailInfo : [];
    },
    filteredEventsInfo() {
      return !this.fetchMeetingLoading ? this.eventsInfo : [];
    },
    
  }
};
</script>

<style scoped>
.tagsContainer {
  margin-bottom: 24px;
}

.flexContainer6 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 4px;
}

.tags {
  padding: 4px 12px;
  border-radius: 50px;
  background-color: #e8edf2;
  color: #222;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  font-size: 14px;
  color: #777;
  margin-bottom: 4px;
}

.tabsContainer {
  overflow-y: auto;
}

.tabsContainer >>> .el-tabs__item {
  font-size: 18px;
  color: #777;
}

.tabsContainer >>> .el-tabs__item.is-active {
  color: #1c3366;
  font-weight: bold;
}

.tabsContainer >>> .el-tabs__active-bar {
  background-color: #1c3366;
}

.tabsContainer >>> .el-tabs__header {
  padding: 0 24px 0 24px;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  margin: 0 0 0;
}
.task-container >>> .task-header {
  margin: 0 24px 8px 24px;
}

.create-email{
    padding: 10px;
    color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 10px;
}
</style>
