<template>
    <div 
    v-if="permission"
    class="no_data_page"
    >
       <img
        src="./assets/nodata.png"
        alt="noData"
        >
       <p class="info">
        Looks like there is no {{ message }}
       </p>
    </div>
    <div 
    v-else
    class="no_data_page"
    style="padding: 10px;"
     >
       <img
        src="./assets/error.png"
        alt="noData"
        >
       <p class="info">
        You will not be able to create and edit meetings and emails as you have not provided the required permissions during Integration, please disconnect and Integrate again with all the email related permissions.
       </p>
    </div>
</template>

<script>
    export default {
        props:{
            message:{
                type: String,
                default: 'email.'
            },
            permission:{
                type:Boolean,
                default:true
            }
        }
    }
</script>

<style scoped>
.no_data_page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    height: 100%;
}

.info{
    font-size: 14px;
    font-weight: 400;
}
</style>