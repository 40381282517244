import { defineStore } from "pinia";

export const useEmailStore = defineStore("email", {
  state: () => {
    return {
        isGoogleAccount:false,
        isMicrosoftAccount:false,
        isGoogleOrMicrosoft:null,
    };
  },
  actions: {
    async SET_ACTIVE_ACCOUNT(active) {
        if(active==="gmail"){
            this.isGoogleAccount=true;
        }
        else if(active==="outlook"){
            this.isMicrosoftAccount=true;
        }else{
        this.isGoogleAccount=false;
        this.isMicrosoftAccount=false;
        }
    },
    async GET_ALL_MAILS(type){
      this.isGoogleOrMicrosoft=type;
    }
  
  },
});
