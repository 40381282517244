<template>
    <div class="main-container">
        <el-dialog 
        :title="popupHeader"
        :visible.sync="openDrawer" 
        width="60%"
        append-to-body 
        :before-close="onCloseClick"
        :close-on-click-modal="false"
          class="dialog">
          <div class="email-dialog-conatiner">
           <div class="top-email-format">
            <!-- to -------------------------------------------------------------------------------------------->
            <div class="email-header">
                <p>
                    To   
                    <span class="required-class">
                                    *
                                </span>
                                 :
                </p>
                <div class="email-details">
                    <div v-if="fetchingData">
                        <i 
                    class="el-icon-loading" 
                     />
                    </div>
                    <div
                    v-for="(item, index) in toEmail"
                    v-else
                    :key="index"
                     class="email-details-container"
                    style="display: flex"
                    >
                    <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'to')"
                         >
                    </el-tooltip>

                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showEmailInputField"
                        ref="emailInput"
                        v-model="extraMail"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('to')"
                        :autofocus="true"
                        @focus="removeError('to')"
                        @blur="addEmail('to')"
                        @keydown="handleKeyEvents($event, 'to')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="!showEmailInputField ? 'Add Email' : checkEmailValidation(extraMail) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                    :style="{ marginLeft: showEmailInputField ? '10px' : '0px' , cursor:checkEmailValidation(extraMail) ? 'not-allowed': 'pointer'}"
                     @click="addNewEmailField('to')"
                     >
                     <i 
                     v-if="!showEmailInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>

                    <div 
                        class="extra_mail_container"
                        > 
                            <p
                            v-if="!isCcVisible"
                            class="extra_mail_text"
                            @click="showExtraFields('cc')"
                            >
                            Cc
                            </p>
                            <p
                            v-if="!isBccVisible"
                            class="extra_mail_text"
                            @click="showExtraFields('bcc')"
                            >
                            Bcc
                            </p>
                       </div>
                    </div> 
            </div>
            <!-- cc----------------------------------------------------------------------------------- -->
            <div
            v-if="isCcVisible"
             class="email-header" 
            >
                <p>Cc : </p>
                <div
                v-for="(item, index) in CC"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'cc')"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showCcInputField"
                        ref="ccInput"
                        v-model="extraCC"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('cc')"
                        @focus="removeError('cc')"
                        @blur="addEmail('cc')"
                        @keydown="handleKeyEvents($event,'cc')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="!showCcInputField ? 'Add Email' : checkEmailValidation(extraCC) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showCcInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(extraCC) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField('cc')"
                     >
                     <i 
                     v-if="!showCcInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>
            <!-- bcc----------------------------------------------------------------------------------- -->
            <div 
            v-if="isBccVisible"
            class="email-header"
            >
                <p>Bcc : </p>
                <div
                v-for="(item, index) in BCC"
                :key="index"
                 class="email-details-container"
                 >
                 <el-tooltip
                    class="item" 
                   effect="dark"
                    :content="item"
                     placement="top-start"
                     >
                   <p class="email-send-to">{{item}}</p>
                   </el-tooltip>
                   <el-tooltip
                    class="item" 
                   effect="dark"
                    content="Remove Email"
                     placement="top-start"
                     >
                        <img
                         src="./assets/X.svg"
                         alt="close"
                         class="close_image_class"
                         @click="removeEmail(item, 'bcc')"
                         >
                    </el-tooltip>
                </div>
                <div>
                    <div class="email_input_new">
                    <input 
                        v-if="showBccInputField"
                        ref="bccInput"
                        v-model="extraBCC"
                        name="email"
                        type="text"
                        class="input_email"
                        :style="handleEmailError('bcc')"
                        @focus="removeError('bcc')"
                        @blur="addEmail('bcc')"
                        @keydown="handleKeyEvents($event,'bcc')"
                    >
                </div>
                </div>
      
                <el-tooltip
                    class="item" 
                   effect="dark"
                     :content="!showBccInputField ? 'Add Email' : checkEmailValidation(extraBCC) ? 'Disabled':'Remove Email'"
                     placement="top-start"
                     >
                     <div 
                     class="add-more-email"
                     :style="{ 
                        marginLeft: showBccInputField ? '10px' : '0px' , 
                     cursor:checkEmailValidation(extraBCC) ? 'not-allowed': 'pointer'
                     }"
                     @click="addNewEmailField('bcc')"
                     >
                     <i 
                     v-if="!showBccInputField"
                     class="el-icon-plus"
                     />

                     <i 
                     v-else 
                     class="el-icon-close"
                     />
                     </div>
                    </el-tooltip>
            </div>
            <!-- subject----------------------------------------------------------------------------------- -->
            <div 
            class="email-header" v-if="flag!=='reply'">
                <p style="width: 55px;">
                    Subject : 
                </p>
                <div class="input-group">
                       <input 
                       v-model="subject"
                       type="text" 
                       maxlength="100"
                       class="input-info"
                       >
                </div>
            </div>
            <!-- body------------------------------------------------------------------------------------------------------------- -->
            <div 
            class="email_body_class"
             >
                <el-input
                    v-model="body"
                    type="textarea"
                    maxlength="3000"
                    :autosize="false"
                    placeholder="Please input email description"
                    class="text_area"
                    show-word-limit
                    />
            </div>
           </div>
           <div 
           class="bottom-email-format" 
           >
            <div class="footer">
                <el-tooltip
             class="item"
             effect="dark" 
            :content=" toEmail.length>0 ? 'Save': 'Fill all the mandatory fields'"
             placement="top-start"
             >
                <el-button
                 type="primary"
                 :disabled="operationalButtonDisabilityCheck"
                 @click="operationalButton"
                 >
                    <p v-if="!loading">
                        Send
                    </p>
                    <i 
                    v-else
                    class="el-icon-loading" 
                     />
                </el-button>
                </el-tooltip>
            </div>
           </div>
          </div>
        </el-dialog>
</div>
</template>
<script>
import {checkEmailValidation} from "../../core/utils/utils";
import { useLeadStore } from '../../stores/lead';
import { mapState,mapActions } from 'pinia';
import {emailExtraExtractor, mergeAndRemove} from "./utils/utils"
import API from "@/services/api/";
import { useEmailStore } from "../../stores/email";
export default {

    emits: ["onClickClose", "addEmailToListEmailView"],

props:{
    openDrawer: {
      type: Boolean,
      default: false,
    },
    emailData:{
        type:Object,
        default:() => {},
    },
    leadData:{
        type:Object,
        default:() => {},
    },
    flag:{
        type:String,
        default:"create"
    },
    popupHeader:{
        type:String,
        default:"Create New Email"
    },
    admin:{
        type:String,
        default:null
    },
    tabType:{
        type: String,
        default: "leadSummary",
     }
  },
  data(){
    return{
      toEmail:[],
      extraToEmail:[],
      fromEmail: "",
      subject:"",
      body:"",
      CC:[],
      BCC:[],
      extraMail:"",
      extraCC:"",
      extraBCC:"",
      showEmailInputField:false,
      showCcInputField:false, 
      showBccInputField:false,
      emailError:false,
      isCcVisible:false,
      isBccVisible:false,
      loading:false,
      visibleData:[],
      fetchingData:false,
      toEmailError:false,
      ccEmailError:false,
      bccEmailError:false,
      allowedToAddTo:true,
      allowedToAddCc:true,
      allowedToAddBcc:true,
    }
  },

  computed:{

    ...mapState(useLeadStore, {
            leadInfo: state => state
        }),
    ...mapState(useEmailStore,{
        isGoogleAccount:state=>state.isGoogleAccount,
        isMicrosoftAccount:state=>state.isMicrosoftAccount,
    }),

    operationalButtonDisabilityCheck(){
        switch(this.flag){
            case "create":
           if(this.toEmail.length > 0){
            return false
           }else{
            return true
           }
            case "forward":
            if(this.toEmail.length > 0){
            return false
           }else{
            return true
           }
           case "reply":
            if(this.toEmail.length > 0){
            return false
           }else{
            return true
           }
            default:
            return false
        }
    },
  },

  watch:{
    showEmailInputField(newValue){
        if(newValue){
            this.$nextTick(() => {
          this.$refs.emailInput.focus();
        });
        }
    },
    showCcInputField(newValue){
        if(newValue){
            this.$nextTick(() => {
          this.$refs.ccInput.focus();
        });
        }
    },
    showBccInputField(newValue){
        if(newValue){
            this.$nextTick(() => {
          this.$refs.bccInput.focus();
        });
        }
    },
 
    
  },



  mounted(){
  
    if(this.flag !== "create"){
    this.getEmailData()
  }
  this.visibleFieldAccordingToFlag()
},


  methods:{
    ...mapActions(useEmailStore, {
      getAllMails: "GET_ALL_MAILS",
    }),
    checkEmailValidation,
     onCloseClick(){
        this.$emit('onClickClose');
    },


    operationalButton(){
        this.onOperationalButtonClick(); return
    },

    async onOperationalButtonClick(){
        this.loading = true
        const data = {
            to: this.toEmail,
            subject: this.subject,
            body: this.body,
            cc: this.CC,
            bcc: this.BCC,
        }

        switch(this.$props.flag){
            case "create":
            try{
                const response =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.CREATE_EMAIL(data):await API.EXTERNAL_INTEGRATION.CREATE_MICROSOFT_MAIL(data);
                const updatedData = {
                    messages:[{
                        id:this.isMicrosoftAccount?response.data.sent_email.email_id : response.data.data.id,
                    to:this.toEmail[0] ,
                    from:this.admin,
                    subject:this.subject !== "" ? this.subject : "No Subject",
                    status:"Read",
                    date:this.isMicrosoftAccount?response.data?.sent_email?.date :  response.data.data.date
                    }],
                    thread_id :this.isMicrosoftAccount?response.data?.sent_email.thread_id :response?.data?.data?.thread_id
                }
               if(this.checkLeadEmailPresent()){
                   this.$emit("addEmailToListEmailView", updatedData)
               }
                this.$message({
                showClose: true,
                message: 'Email sent successfully',
                type: "success",
                center: true
                })
            }
            catch(err){
                let message = "Unable to create email,please disconnect and Integrate again with all the email related permissions.";
             if(err.response.data.error){
                const errorMessage =err.response.data.error.toString()
                const detailsRegex = /Details: "(.*)"/;
                const detailsMatch = errorMessage.match(detailsRegex);
            if(detailsMatch){
                const parsedDetails = JSON.parse(detailsMatch[1].replace(/'/g, '"'));
                if(parsedDetails[0].reason == "insufficientPermissions")
                message = "You don't have enough permissions to create email, please disconnect and Integrate again with all the email related permissions."
            }
        }
                this.$message({
                showClose: true,
                message: message,
                type: "error",
                center: true
                 })
            }
            this.loading = false
            this.$emit('onClickClose');
                break;
            case "reply":
         
                const replyData = {
                    to:this.toEmail,
                    cc: this.CC,
                    // subject: this.subject,
                    bcc: this.BCC,
                    body: this.body
                }
            try{
                const response = this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.REPLY_EMAIL(this.emailData.id, replyData):await API.EXTERNAL_INTEGRATION.REPLY_MICROSOFT_EMAIL(this.emailData.id, replyData)
                    const updatedReplyData = {
                        messages:[{
                            id:this.isMicrosoftAccount?(response.data?.email_id ||response.data.sent_email.email_id ):response.data.data.id,
                        to:this.toEmail[0],
                        from:this.admin,
                        subject:this.isMicrosoftAccount?response.data.subject : this.subject !== "" ? this.subject : "No Subject",
                        status:"Read",
                        date:this.isMicrosoftAccount?response.data.date :response.data.data.date
                        }],
                        thread_id :this.isMicrosoftAccount?(response.data?.thread_id||response.data.sent_email.thread_id) :response.data.data.thread_id
                    }
                    if(this.checkLeadEmailPresent()){
                        this.$emit("addEmailToListEmailView", updatedReplyData)
                    }
    
                    this.$message({
                        showClose: true,
                        message: 'Replied successfully.',
                        type: "success",
                        center: true
                    })
               

            }catch(err){
                let message = "Unable to reply to this email, please disconnect and Integrate again with all the email related permissions."
                if(err.response.data.error){
                const errorMessage =err.response.data.error.toString()
                const detailsRegex = /Details: "(.*)"/;
                const detailsMatch = errorMessage.match(detailsRegex);
                if(detailsMatch){
                const parsedDetails = JSON.parse(detailsMatch[1].replace(/'/g, '"'));
                if(parsedDetails[0].reason == "insufficientPermissions")
                message = "You don't have enough permissions to reply email, please disconnect and Integrate again with all the email related permissions."
                }
                }
                this.$message({
                    showClose: true,
                    message: message,
                    type: "error",
                    center: true
                })
            }
            this.loading = false
            this.$emit('onClickClose');
                break;
            case "forward":
                const forwardData = {
                    to:this.toEmail,
                    cc: this.CC,
                    subject: this.subject,
                    bcc: this.BCC,
                    body:this.body
                }
                try{
                    const response =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.FORWARD_EMAIL(this.emailData.id, forwardData): await API.EXTERNAL_INTEGRATION.FORWARD_MICROSOFT_EMAIL(this.emailData.id, forwardData);

                      const forwardedData = {
                          messages:[{
                          id:this.isMicrosoftAccount?response.data?.forwarded_email.email_id : response.data.data.id,
                      to:this.toEmail[0],
                      from:this.admin,
                      subject:this.subject !== "" ? this.subject : "No Subject",
                      status:"Read",
                      date:this.isMicrosoftAccount?response.data?.forwarded_email.date :response.data.data.date
                      }],
                      thread_id :this.isMicrosoftAccount?response.data?.forwarded_email.thread_id :response.data.data.thread_id
                      }
                      if(this.checkLeadEmailPresent()){
                          this.$emit('addEmailToListEmailView', forwardedData)
                      }
                      this.$message({
                      showClose: true,
                      message: 'Email forwarded successfully.',
                      type: "success",
                      center: true
                  })
                }catch(err){
                    let message = "Unable to reply to this email, please disconnect and Integrate again with all the email related permissions."
                    if(err.response.data.error){
                    const errorMessage =err.response.data.error.toString()
                    const detailsRegex = /Details: "(.*)"/;
                    const detailsMatch = errorMessage.match(detailsRegex);
                    if(detailsMatch){
                     const parsedDetails = JSON.parse(detailsMatch[1].replace(/'/g, '"'));
                    if(parsedDetails[0].reason == "insufficientPermissions")
                     message = "You don't have enough permissions to forward email, please disconnect and Integrate again with all the email related permissions."
                }
                }
                    this.$message({
                    showClose: true,
                    message:message,
                    type: "error",
                    center: true
                })
                }
                this.loading = false
                this.$emit('onClickClose');
                break;
            default:
                break;
        }
 
    },
    async getEmailData(){
        this.fetchingData = true
        try{
            const {data} =this.isGoogleAccount? await API.EXTERNAL_INTEGRATION.GET_EMAIL_INFO_BASED_ON_ID(this.emailData.id):await API.EXTERNAL_INTEGRATION.GET_MICROSOFT_EMAIL_INFO_BASED_ON_ID(this.emailData.id);
            this.visibleData = data.email;
            if(this.isMicrosoftAccount){
                   const headers={
                    to:this.visibleData.toRecipients.join(', '),
                    from:this.visibleData.from,
                    cc:this.visibleData.ccRecipients.join(', '),
                    subject:this.visibleData.subject,
                   }
                   this.visibleData['headers']=headers;
                }
            const toEmailArray = this.visibleData.headers.to ? this.visibleData.headers.to.split(',').map(email => email.trim()) : this.visibleData.headers.To.split(',').map(email => email.trim())
            const replyToEmailArray = this.visibleData.headers.from ? this.visibleData.headers.from.split(',').map(email => email.trim()) : this.visibleData.headers.From.split(',').map(email => email.trim())
            const ccArray = this.visibleData.headers.cc ? this.visibleData.headers.cc.split(',').map(email => email.trim()) : []
            const CcArray = this.visibleData.headers.Cc ? this.visibleData.headers.Cc.split(',').map(email => email.trim()) : []
            this.toEmail=mergeAndRemove(toEmailArray, replyToEmailArray, this.admin)
            this.CC = ccArray.length > 0 ? emailExtraExtractor(ccArray) : emailExtraExtractor(CcArray)
            this.subject = this.visibleData.headers.subject ?  this.visibleData.headers.subject : this.visibleData.headers.Subject
            }catch(e){
                this.$message({
                showClose: true,
                message: 'Cannot get email details',
                type: "error",
                center: true
            })
        }
        this.fetchingData = false
    },

    addNewEmailField(type){
      switch(type){
        case "to":
        if(checkEmailValidation(this.extraMail)){
            this.allowedToAddTo = true
                return
            }
            if(this.allowedToAddTo){
                this.extraMail = ""
                this.showEmailInputField = !this.showEmailInputField
                this.toEmailError = false
                this.allowedToAddTo = true
            }else{
                this.toEmailError = false
                this.allowedToAddTo = true
            }
            break
        case "cc":
        if(checkEmailValidation(this.extraCC)){
                return
            }
            if(this.allowedToAddCc){
                this.extraCC = ""
                this.showCcInputField = !this.showCcInputField
                this.ccEmailError = false
            }else{
                this.allowedToAddCc = true
                this.ccEmailError = false
                return
            }
            break
        case "bcc":
        if(checkEmailValidation(this.extraBCC)){
                return
            }
            if(this.allowedToAddBcc){
                this.extraBCC = ""
                this.showBccInputField = !this.showBccInputField
                this.bccEmailError = false
            }else{
                this.bccEmailError = false
                this.allowedToAddBcc = true
                return 
            }
            break
        default:
            break;
      }
    },

    addEmail(type){
        switch(type){
            case "to":
            if(!checkEmailValidation(this.extraMail)){
                this.toEmailError = true
                return
            }
            this.toEmail.push(this.extraMail)
            this.extraMail = ""
            this.showEmailInputField = !this.showEmailInputField
            this.toEmailError = false
            this.allowedToAddTo = false
            setTimeout(() => this.allowedToAddTo = true, 100)
                break
            case "cc":
            if(!checkEmailValidation(this.extraCC)){
                this.ccEmailError = true
                return
            }
            this.CC.push(this.extraCC)
            this.extraCC = ""
            this.showCcInputField = false
            this.ccEmailError = false
            this.allowedToAddCc = false
            setTimeout(() => this.allowedToAddCc = true, 100)
                break
            case "bcc":
            if(!checkEmailValidation(this.extraBCC)){
                this.bccEmailError = true
                return
            }
            this.BCC.push(this.extraBCC)
            this.extraBCC = ""
            this.showBccInputField = false
            this.bccEmailError = false
            this.allowedToAddBcc = false
            setTimeout(() => this.allowedToAddBcc = true, 100)
                break
            default:
                return
        }
    },

    removeError(type){
        switch(type){
            case "to":
            this.toEmailError = false
                break
            case "cc":
            this.ccEmailError = false
                break
            case "bcc":
            this.bccEmailError = false
                break
            default:
                return
        }
    },

    showExtraFields(type){
        switch(type){
            case "cc":
                this.isCcVisible = true
                break
            case "bcc":
                this.isBccVisible = true
                break
            default:
                break
        }
    },

    handleEmailError(type){
        switch(type){
            case "to":
            if(this.toEmailError ){
                return    {
                border: "1px solid red",
                color:"red"
            }
            }
                break
            case "cc":
            if(this.ccEmailError){
                return    {
                border: "1px solid red",
                color:"red"
            }
            }
                break
            case "bcc":
            if(this.bccEmailError){
                this.bccEmailError = true
                return {
                border: "1px solid red",
                color:"red"
            }
            }
                break
            default:
                return
        }
        },



    removeEmail(data, type){
        switch(type){
            case "to":
                const updatedToEmailArray = this.toEmail.filter(item =>item !== data)
                this.toEmail = updatedToEmailArray
                break
            case "cc":
                const updatedCcArray = this.CC.filter(item =>item !== data)
                this.CC = updatedCcArray
                break
            case "bcc":
                const updatedBccArray = this.BCC.filter(item =>item !== data)
                this.BCC = updatedBccArray
                break
            default:
                return
        }
    },

    visibleFieldAccordingToFlag(){
             if(this.tabType == "designSummary"){
                this.toEmail.push(this.leadInfo.email)
             }else{
                if(this.leadData.lead_details.email){
                    this.toEmail.push(this.leadData.lead_details.email)
                }
                else{
                    this.toEmail.push(this.leadData.email)
                }
             } 
    },

    handleKeyEvents(event, type){

        switch(type){
            case "to":
                this.toEmailError = false;
            if(event.key == "Enter"){
            this.addEmail("to")
            }
                break;
            case "cc":
                this.ccEmailError = false
            if(event.key == "Enter"){
            this.addEmail("cc")
            }
                break;
            case "bcc":
            this.bccEmailError = false
            if(event.key == "Enter"){
            this.addEmail("bcc")
            }
                break;
            default:
                return
        }
    },

    checkLeadEmailPresent(){
        let result = false;
        let leadEmail = ""
        if(this.tabType == "designSummary"){
            leadEmail = this.leadInfo.email
        }else{
            leadEmail = this.leadData.lead_details ? this.leadData.lead_details.email :this.leadData.email
        }
        const toEmailCheck = this.toEmail.includes(leadEmail)
        const ccEmailCheck  = this.CC.includes(leadEmail)
        const bccEmailCheck = this.BCC.includes(leadEmail)

        if(toEmailCheck || ccEmailCheck || bccEmailCheck){
            result = true
        }
        return result
    }
  }
};

</script>

<style  scoped>

.dialog >>> .el-dialog{
    border-radius: 8px;
    padding-bottom: 10px
}

.dialog >>> .el-dialog__header{
    background-color: #E8EDF2;
    border-radius: 8px 8px 0px 0px;
}

.dialog >>> .el-dialog__title{
    color: black !important;
    width: 180px;
    margin-right: auto;
    font-size: 16px;
    font-weight: 600;
}

.dialog >>> .el-dialog__headerbtn .el-dialog__close{
    color: black  !important  ;
    font-size: larger;
    font-weight: bold;
}

.dialog >>> .el-dialog__headerbtn .el-dialog__close :hover{
    color: black  !important  ;
    font-size: larger;
    font-weight: bold;
}

.email_body_class >>> .el-textarea__inner{
    resize: none;
    height: 30vh;
    border: none;
}

.email_body_class >>> .el-textarea__inner::-webkit-scrollbar-thumb{
    cursor:pointer;
}
.email_body_class >>>.el-textarea__inner::placeholder{
   color: #777777;
   font-size: 16px;
   font-weight: 400;
}

.email-header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:5px;
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.text_area{
    overflow-y: hidden;
}

.input_email {
    padding: 10px;
    background-color: #ECF5FF;
    border: 1px solid #CCCCCC;
    color: #409EFF;
    margin-left: 10px;
    border-radius: 4px 0px 0px 4px;
    margin-right: -10px
}

.email-details-container{
    display: flex;
    width: 170px;
    padding: 10px;

    background-color:  #ECF5FF;
    margin-left: 10px;
    border: 1px solid #CCCCCC;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    cursor: pointer;
}

.email-details{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:5px;
    width: calc(100% - 50px)
}

.email-send-to{
    color: #409EFF;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.extra_email{
    margin-top: 20px;
    margin-left: 5px;
    cursor: pointer;
}

.close_image_class{
    height: 18px;
    width: 18px;
}

.add-more-email{
    display: flex;
    width: 35px;
    align-items: center;
    justify-content: center;
    padding: 14px;
    padding-bottom: 10px;
    color: #777777;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    cursor: pointer;
}

.input-group{
    width: calc(100% - 100px);
}

.extra_mail_container{
    display: flex; 
    align-items: center;
    margin-left: auto;
    width:60px;
    margin-bottom: 10px;
    justify-content: space-between
}

.input-info{
    padding: 5px;
    border: none;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    background-color: white;
}

.email_body_class {
border-bottom:1px solid #CCCCCC;
}

.extra_mail_text{
    color: #1C3366;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.required-class{
    color: red;
}

.footer{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 10px;
}

input[disabled] {
    cursor: not-allowed;
    color:#CCCCCC;
}

</style>